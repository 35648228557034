<template>
  <div class="columns is-multiline is-marginless">
    <div class="column is-12">
      <form @submit.prevent="login">
        <hgroup class="has-text-centered has-margin-bottom-150">
          <a v-if="!isWhitelabeled" href="https://fixed.net/" target="_blank">
            <img
              src="/assets/images/interface/logos/fixed.svg"
              alt="Fixed"
              width="160"
              class="is-hidden-desktop has-margin-bottom-100 has-margin-top-100"
            />
          </a>
          <b-icon
            v-else
            icon="user-circle"
            size="is-large"
            class="has-margin-bottom-100"
          />
          <h2 class="is-size-2 is-size-3-touch has-margin-bottom-75">
            Login to your account.
          </h2>
          <p v-if="!isWhitelabeled">
            Don't yet have an account?
            <router-link :to="{ name: 'register' }">
              <u>Sign up here</u> </router-link
            >.
          </p>
        </hgroup>

        <div class="columns is-relative">
          <div class="column">
            <b-field label="Email">
              <b-input
                v-model="form.email"
                :disabled="!!processing"
                type="email"
                autocomplete="email"
                placeholder="Your email"
              />
            </b-field>

            <b-field label="Password">
              <b-input
                v-model="form.password"
                :disabled="!!processing"
                type="password"
                autocomplete="current-password"
                placeholder="Your password"
              />
            </b-field>

            <p
              v-if="!isWhitelabeled"
              class="is-size-7 has-text-right has-text-centered-mobile"
            >
              <a class="has-text-grey-light" @click="forgotPassword"
                >Forgot password?</a
              >
            </p>

            <b-field class="has-text-centered has-margin-top-100">
              <button
                :disabled="formIsDisabled"
                :style="{ minWidth: '15rem' }"
                :class="{
                  'is-loading': processing === 'password',
                  'is-dark': isWhitelabeled,
                  'is-primary': !isWhitelabeled
                }"
                type="submit"
                class="button is-rounded is-medium"
              >
                <strong class="is-size-6">Login</strong>
              </button>
            </b-field>
          </div>
        </div>
      </form>
    </div>

    <div v-if="!isWhitelabeled" class="column is-12">
      <p class="has-text-centered has-text-grey-light is-size-7">
        Or login with&hellip;
      </p>
    </div>

    <div
      v-if="!isWhitelabeled"
      class="columns is-multiline has-padding-50"
      style="margin: -0.25em;"
    >
      <div
        v-for="p in OAuthProviders()"
        :key="p.provider.providerId"
        class="column is-6 has-padding-25"
      >
        <button
          :class="{ 'is-loading': processing === p.provider().providerId }"
          :disabled="!!processing"
          type="button"
          class="button is-medium is-fullwidth"
          @click="loginWithProvider(p)"
        >
          <b-icon
            v-if="processing !== p.provider().providerId"
            :icon="p.icon.code"
            :pack="p.icon.pack"
            :style="{ color: p.color }"
            size="is-small"
          />

          <span class="is-size-6">{{ p.label }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "Login",
  data() {
    return {
      processing: "",
      form: {
        email: this.$route.query.email || "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      providers: state => state.auth.providers
    }),
    ...mapGetters({
      OAuthProviders: "auth/OAuthProviders"
    }),
    isWhitelabeled() {
      return this.$store.getters["ui/isWhitelabeled"]();
    },
    formIsValid() {
      if (!this.$v.isEmail(this.form.email)) return false;
      if (!this.form.password.length) return false;
      return true;
    },
    formIsDisabled() {
      return !this.formIsValid || !!this.processing;
    },
    userIsAuthenticated() {
      return this.$store.getters["auth/isAuthenticated"]();
    }
  },
  watch: {
    userIsAuthenticated() {
      if (this.userIsAuthenticated) {
        if (this.$router.currentRoute.query.redirect) {
          this.$router.push({ path: this.$router.currentRoute.query.redirect });
        } else {
          this.$router.push({ name: "admin" });
        }
      }
    }
  },
  methods: {
    login() {
      if (!this.isWhitelabeled) this.loginWithPassword();
      else this.loginReseller();
    },
    loginWithProvider(p) {
      this.processing = p.provider().providerId;
      this.$store
        .dispatch("auth/loginWithProvider", { provider: p.provider() })
        .then(result => this.loginSuccess(result))
        .catch(error => this.loginError(error));
    },
    loginReseller() {
      this.processing = "password";
      this.$store
        .dispatch("auth/loginReseller", this.form)
        .then(result => {
          if (this.$_.isArray(result)) {
            this.selectResellerModal(result);
          } else {
            this.loginSuccess(result);
          }
        })
        .catch(error => this.loginError(error));
    },
    selectResellerModal(resellers) {
      this.$modal.open({
        parent: this,
        component: () => import("@shared/reseller/_loginSelectResellerModal"),
        canCancel: [],
        width: 400,
        props: {
          resellers: resellers,
          email: this.form.email,
          password: this.form.password
        }
      });
    },
    loginWithPassword() {
      this.processing = "password";
      this.$store
        .dispatch("auth/loginWithPassword", this.form)
        .then(result => this.loginSuccess(result))
        .catch(error => this.loginError(error));
    },
    loginSuccess(result) {
      if (result && result.message) {
        this.$toast.open({
          message: result.message,
          position: "is-bottom",
          queue: false
        });
      }
    },
    loginError(error) {
      this.processing = "";
      if (error && error.message) {
        this.$snackbar.open({
          message: error.message,
          position: "is-bottom",
          type: "is-danger",
          duration: 10000,
          queue: false
        });
      }
    },
    forgotPassword() {
      this.$modal.open({
        parent: this,
        component: () => import("./_forgottenPasswordModal"),
        canCancel: ["escape", "outside"],
        width: 400,
        props: {
          email: this.form.email
        }
      });
    }
  }
};
</script>
